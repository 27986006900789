import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';

export default () => {
  const uuid = '68acbb9ff5e1478fae369de353533101';
  // 请求二维码
  const loadQrcode = async () => {
    try {
      const data = await fetch(
        `https://z.convertlab.com/api/extvideo/qrcode/create?type=page&uuid=${uuid}`,
        {
          method: 'post',
          body: JSON.stringify({
            replyText: '扫码成功，请前往PC端页面查看状态！',
            replyType: 'text',
          }),
        }
      ).then((res) => res.json());
      return data;
    } catch (err) {
      console.log(err);
    }
  };
  // 轮询扫码结果
  let interval = null;
  const [qrUrl, setQrUrl] = useState('');
  useEffect(() => {
    loadQrcode().then((data = {}) => {
      if (data.qrUrl) {
        setQrUrl(data.qrUrl);
        interval = setInterval(async () => {
          try {
            const result = await fetch(
              `https://z.convertlab.com/api/extvideo/qrcode/getScanResult?type=page&uuid=${uuid}`,
              {
                method: 'post',
                body: JSON.stringify({ qrUuid: data.uuid }),
              }
            ).then((res) => res.json());
            console.log(result);
            if (result && result.success) {
              clearInterval(interval);
            }
          } catch (err) {
            console.log(err);
            clearInterval(interval);
          }
        }, 1000);
      }
    });
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <Layout current="sources" subCurrent="maturity" type="tool">
      <main className="maturity">
        <div>数字化营销成熟度诊断</div>
        <img src={qrUrl} alt="QR-code" />
      </main>
    </Layout>
  );
};
